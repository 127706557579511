<template>
    <div v-loading="loading" style="padding: 10px; min-height: 100px;">
        <el-row v-if="transaction != null" :gutter="20">
            <el-col v-if="transaction.payerName != null || transaction.receiverName != null" :span="6">
                <el-card class="box-card">
                    <el-descriptions v-if="transaction.payerName != null" title="Betalare" :column="1" size="small">
                        <el-descriptions-item v-if="transaction.payerName != null" label="Namn">{{ transaction.payerName }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.payerAddress != null" label="Adress">{{ transaction.payerAddress }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.payerCity != null" label="Postadress">{{ transaction.payerPostalCode }} {{ transaction.payerCity }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.payerCountry != null" label="Land">{{ transaction.payerCountry }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.payerOrgNo != null" label="Orgnr">{{ transaction.payerOrgNo }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.payeraccountType != null" label="Kontotyp">{{ transaction.payerAccountType }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.payerAccountNumber != null" label="Kontonummer">{{ transaction.payerAccountNumber }}</el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions v-if="transaction.receiverName != null" title="Mottagare" :column="1" size="small">
                        <el-descriptions-item v-if="transaction.receiverName != null" label="Namn">{{ transaction.receiverName }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.receiverAddress != null" label="Adress">{{ transaction.receiverAddress }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.receiverCity != null" label="Postadress">{{ transaction.receiverPostalCode }} {{ transaction.receiverCity }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.receiverCountry != null" label="Land">{{ transaction.receiverCountry }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.receiverOrgNo != null" label="Orgnr">{{ transaction.receiverOrgNo }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.receiverAccountType != null" label="Kontotyp">{{ transaction.receiverAccountType }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.receiverAccountNumber != null" label="Kontonummer">{{ transaction.receiverAccountNumber }}</el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card class="box-card">
                    <el-descriptions title="Info" :column="1" size="small">
                        <el-descriptions-item label="Klientnummer">{{ transaction.clientNumber }}</el-descriptions-item>
                        <el-descriptions-item label="Klient">{{ transaction.clientName }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.textStruct != null && transaction.textStruct.length > 0" label="Meddelande">{{ transaction.textStruct }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.textUnstruct != null && transaction.textUnstruct.length > 0" label="Meddelande">{{ transaction.textUnstruct }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.message != null" label="Meddelande">{{ transaction.message }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.refText != null" label="Referens">
                            <template>
                                <div v-if="transaction.refUri == null">{{ transaction.refText }}</div>

                                <router-link v-else :to="transaction.refUri">
                                    {{ transaction.refText }}
                                </router-link>
                            </template>
                        </el-descriptions-item>
                        <el-descriptions-item v-if="transaction.ocr != null" label="Ocr">{{ transaction.ocr }}</el-descriptions-item>
                        <el-descriptions-item label="Belopp">{{ transaction.amount }} SEK</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.originalCurrency != 'SEK'" label="Original">{{ transaction.originalAmount }} {{ transaction.originalCurrency }}</el-descriptions-item>
                        <el-descriptions-item v-if="transaction.originalCurrency != 'SEK'" label="Växelkurs">{{ transaction.originalExchangeRate }}</el-descriptions-item>
                    </el-descriptions>
                </el-card>
            </el-col>
            <el-col :span="6">
                <el-card class="box-card">
                    <el-descriptions title="Aktion" :column="1" size="small" />
                    <el-button @click="openRefund" type="primary">Återbetalning</el-button>
                </el-card>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import moment from "moment";
import Api from "./../bank_files.api";

export default {
    props: {
        id: {
            type: Number,
            default: 0,
        },
        subId: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            transaction: null,
            loading: false,
        };
    },
    async created() {
        this.loading = true;
        if (this.subId == 0) {
            const transactions = await Api.getChildren(this.id);
            this.transaction = transactions[0];
        } else {
            this.transaction = await Api.getChild(this.id, this.subId);
        }
        this.loading = false;
    },
    methods: {
        formatDate(date) {
            return moment(date).format("YYYY-MM-DD");
        },
        openRefund() {
            this.$emit("openRefundModal", { id: this.id, subId: this.subId });
        },
    },
};
</script>
<style></style>
